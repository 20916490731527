import { useEffect, useRef } from 'react';
import { logger } from '../../../infrastructure';

export const usePropsUpdateTracer = (componentName: string, props: Record<string, unknown>): void => {
  const prev = useRef(props);

  logger.info(`${componentName} render()`, {});

  useEffect(() => {
    const changedProps = Object.entries(props)
      .reduce((acc, [k, v]) => prev.current[k] === v ? acc :{ ...acc, [k]: [prev.current[k], v] }, {});

    Object.keys(changedProps).length > 0
      ? logger.info(`${componentName} render() Changed props:`, { keys: Object.keys(changedProps) })
      : logger.info(`${componentName} render() is ignored`, {});

    prev.current = props;
  });
};
