import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextProps, TextStyle } from 'react-native';
import { Environment } from '../../Environment';

export class AppText extends React.Component<TextProps> {
  render() : ReactNode {
    const style = StyleSheet.flatten([
      this.fontFamily(this.props.style),
      this.disableFontPaddingOnAndroid(),
      this.props.style
    ]);
    delete style['fontWeight']; // フォントの太さはfontFamilyで表現するので、fontWeightは削除する
    return <Text {...this.props} style={style}>{this.props.children}</Text>;
  }

  fontFamily(style?: StyleProp<TextStyle>): TextStyle {
    if (!style) {
      return { fontFamily: 'NotoSansJP_400Regular' };
    }
    const fontWeight = StyleSheet.flatten(style).fontWeight;
    if (!fontWeight) {
      return { fontFamily: 'NotoSansJP_400Regular' };
    }
    switch (fontWeight) {
      case '100':
      case '200':
        return { fontFamily: 'NotoSansJP_100Thin' };
      case '300':
        return { fontFamily: 'NotoSansJP_300Light' };
      case '400':
      case 'normal':
        return { fontFamily: 'NotoSansJP_400Regular' };
      case '500':
      case '600':
        return { fontFamily: 'NotoSansJP_500Medium' };
      case '700':
      case '800':
      case 'bold':
        return { fontFamily: 'NotoSansJP_700Bold' };
      case '900':
        return { fontFamily: 'NotoSansJP_900Black' };
    }
  }

  disableFontPaddingOnAndroid(): TextStyle {
    if (Environment.platform.type === 'android') {
      // AndroidのTextで上下に空白ができるので、デフォルトではその空白を表示しないようにする
      return { includeFontPadding: false };
    } else {
      return {};
    }
  }
}
